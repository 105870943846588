<template>
       <el-input-number
        v-model="num"
        size="mini"
        @change="handleChange"
        :min="Number(item.minOrderNum)"
        :max="Number(goodsCount)"
        v-if="item.minOrderNum"
        :step="item.minOrderNum ? Number(item.minOrderNum) : '1'"
        step-strictly
        :precision="0"
      ></el-input-number>
</template>

<script>
  export default {
    name:"InputNumber",
    data(){
        return{
           num:0,
           goodsCount:0
        }
    },
    props:{
        item:{
            type:Object
        }
    },
    methods:{
        handleChange(value) {
          this.num=value
          this.item.num=value
        },
    },
   created(){
      this.goodsCount=Number(Number(this.item.goodsCount) - Number(this.item.goodsCount) % Number(this.item.minOrderNum))
    }
  }
</script>

<style lang="less" scoped>
@import "~style/theme.less";
    .el-input-number {
          width:90px!important;
          border-radius:4px;
          color: #979797 !important;
            height:26px!important;
        }
         /deep/.el-input-number__decrease {
          width:20px !important;
          border-radius:4px;
          height:24px!important;
          line-height:24px!important;
          margin-top:5px;
        }
          /deep/.el-input-number__increase {
            width:20px  !important;
          display: inline-block;
          border-radius:4px;
            height:24px!important;
            line-height:24px!important;
            margin-top:5px;
        }
          /deep/.el-input--mini .el-input__inner{
            padding: 0 20px ;
            height:26px!important;
            line-height:26px!important;
            margin-top: 3px;
        }
  

</style>
